
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledLayout_f4794c15b8311107{flex-direction:column}.LayoutBody_2e405b9b9c174c3b,.StyledLayout_f4794c15b8311107{background-color:var(--backgroundColor_ac29c84fc3055463);display:flex;flex:1}.MainSection_88565475bc98b282{padding:0;width:100%;margin-top:0;margin-bottom:0}.MainSection_88565475bc98b282.withMobileAppBar_ce445e8d23357cb0{margin-top:48px}.MainSection_88565475bc98b282.withMobileBottomBar_cf3569b64325fcb8{margin-bottom:56px}.MainSectionV2_bf856daef7a8535e{padding:0;width:100%;margin-top:0;margin-bottom:0}.MainSectionV2_bf856daef7a8535e.withMobileAppBar_ce445e8d23357cb0{margin-top:80px}.MainSectionV2_bf856daef7a8535e.withMobileBottomBar_cf3569b64325fcb8{margin-bottom:56px}@supports(margin-bottom:calc(56px + env(safe-area-inset-bottom))){.MainSection_88565475bc98b282.withMobileBottomBar_cf3569b64325fcb8,.MainSectionV2_bf856daef7a8535e.withMobileBottomBar_cf3569b64325fcb8{margin-bottom:calc(56px + env(safe-area-inset-bottom))};}@media (min-width:600px),(orientation:landscape){.MainSection_88565475bc98b282{margin-top:48px}.MainSection_88565475bc98b282,.MainSectionV2_bf856daef7a8535e{margin-left:96px;margin-bottom:0;padding:16px 16px 0;flex:1;display:flex;flex-direction:column;align-items:center;overflow:auto}.MainSectionV2_bf856daef7a8535e{margin-top:80px}.MainSection_88565475bc98b282.withMobileBottomBar_cf3569b64325fcb8,.MainSectionV2_bf856daef7a8535e.withMobileBottomBar_cf3569b64325fcb8{margin-bottom:0}@supports(margin-left:calc(96px + env(safe-area-inset-left))){.MainSection_88565475bc98b282,.MainSectionV2_bf856daef7a8535e{margin-left:calc(96px + env(safe-area-inset-left))}}}`;
styleInject(_css)

/** StyledLayout Props */
export type StyledLayoutCCM = {
  /** StyledLayout Component Custom Properties */
  '$backgroundColor': string;

  /** StyledLayout Modifier Flags */
  // No modifiers classes found
};
/** Base StyledLayout component */
export const StyledLayout: ComponentCreator<StyledLayoutCCM> = createComponentCreator({
  "name": "StyledLayout",
  "base": "StyledLayout_f4794c15b8311107",
  "prop": {
    "$backgroundColor": "--backgroundColor_ac29c84fc3055463"
  },
  "mod": {}
});


/** LayoutBody Props */
export type LayoutBodyCCM = {
  /** LayoutBody Component Custom Properties */
  '$backgroundColor': string;

  /** LayoutBody Modifier Flags */
  // No modifiers classes found
};
/** Base LayoutBody component */
export const LayoutBody: ComponentCreator<LayoutBodyCCM> = createComponentCreator({
  "name": "LayoutBody",
  "base": "LayoutBody_2e405b9b9c174c3b",
  "prop": {
    "$backgroundColor": "--backgroundColor_ac29c84fc3055463"
  },
  "mod": {}
});


/** MainSection Props */
export type MainSectionCCM = {
  /** MainSection Component Custom Properties */
  // No custom properties found

  /** MainSection Modifier Flags */
  '$withMobileAppBar'?: boolean;
  '$withMobileBottomBar'?: boolean;
};
/** Base MainSection component */
export const MainSection: ComponentCreator<MainSectionCCM> = createComponentCreator({
  "name": "MainSection",
  "base": "MainSection_88565475bc98b282",
  "prop": {},
  "mod": {
    "$withMobileAppBar": "withMobileAppBar_ce445e8d23357cb0",
    "$withMobileBottomBar": "withMobileBottomBar_cf3569b64325fcb8"
  }
});


/** MainSectionV2 Props */
export type MainSectionV2CCM = {
  /** MainSectionV2 Component Custom Properties */
  // No custom properties found

  /** MainSectionV2 Modifier Flags */
  '$withMobileAppBar'?: boolean;
  '$withMobileBottomBar'?: boolean;
};
/** Base MainSectionV2 component */
export const MainSectionV2: ComponentCreator<MainSectionV2CCM> = createComponentCreator({
  "name": "MainSectionV2",
  "base": "MainSectionV2_bf856daef7a8535e",
  "prop": {},
  "mod": {
    "$withMobileAppBar": "withMobileAppBar_ce445e8d23357cb0",
    "$withMobileBottomBar": "withMobileBottomBar_cf3569b64325fcb8"
  }
});

